<template>
    <section
        v-if="rrss.facebook || rrss.instagram || rrss.twitter || rrss.youtube || rrss.tiktok || rrss.telegram"
        class="footer-followme-block"
    >
        <div class="content-head">
            <h3 class="heading">Follow me</h3>
        </div>
        <div class="footer-followme-block__images">
            <a v-if="rrss.facebook" target="_blank" rel="nofollow" :href="rrss.facebook"
                ><img
                    :src="$assets.social.facebook"
                    alt="Facebook"
                    title="Facebook"
                    width="35px"
                    height="35px"
            /></a>
            <a v-if="rrss.instagram" target="_blank" rel="nofollow" :href="rrss.instagram"
                ><img
                    :src="$assets.social.instagram"
                    alt="Instagram"
                    title="Instagram"
                    width="35px"
                    height="35px"
            /></a>
            <a v-if="rrss.twitter" target="_blank" rel="nofollow" :href="rrss.twitter"
                ><img :src="$assets.social.twitter" alt="Twitter" title="Twitter" width="35px" height="35px"
            /></a>
            <a v-if="rrss.youtube" target="_blank" rel="nofollow" :href="rrss.youtube"
                ><img :src="$assets.social.youtube" alt="Youtube" title="Youtube" width="35px" height="35px"
            /></a>
            <a v-if="rrss.tiktok" target="_blank" rel="nofollow" :href="rrss.tiktok"
                ><img :src="$assets.social.tiktok" alt="Tiktok" title="Tiktok" width="35px" height="35px"
            /></a>
            <a v-if="rrss.telegram" target="_blank" rel="nofollow" :href="rrss.telegram"
                ><img
                    :src="$assets.social.telegram"
                    alt="Telegram"
                    title="Telegram"
                    width="35px"
                    height="35px"
            /></a>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'FooterSocialNetworks',
    props: {
        rrss: {
            type: Object,
            required: true,
        },
    },
})
</script>

<style lang="postcss" scoped>
.footer-followme-block {
    @apply pl-3;
    .content-head {
        @apply mb-2 flex justify-between;
        .heading {
            @apply text-lg font-thin;
        }
    }

    &__images {
        @apply flex gap-1;
    }
}
</style>
