<template>
    <button v-show="showButton" class="button" aria-label="Subir" @click="scrollTop">
        <img
            draggable="false"
            class="icon"
            :src="$assets.white.arrowTop"
            alt="Subir"
            width="20"
            height="20"
        />
    </button>
</template>

<script>
export default {
    name: 'ScrollTop',
    data() {
        return {
            showButton: false,
            listener: null,
        }
    },
    mounted() {
        this.listener = () => {
            const { scrollTop, scrollHeight, clientHeight } = document.documentElement

            this.showButton = scrollTop / (scrollHeight - clientHeight) > 0.3
        }

        document.addEventListener('scroll', this.listener)
    },
    destroyed() {
        document.removeEventListener('scroll', this.listener)
    },
    methods: {
        scrollTop() {
            document.documentElement.style.scrollBehavior = 'smooth'

            window.scroll(0, 1)

            document.documentElement.style.scrollBehavior = 'unset'
        },
    },
}
</script>
<style lang="postcss" scoped>
.button {
    --primary-color: var(--personalized-primary-color, theme('colors.site.primary.DEFAULT'));
    @apply fixed bottom-5 right-5 z-50 flex h-12 w-12 items-center justify-center rounded-full border border-white bg-[--primary-color];
}
.icon {
    @apply h-6 w-6;
}
@media print {
    .button {
        @apply hidden;
    }
}
</style>
