<script lang="ts">
import { defineComponent } from 'vue'

type Option = {
    title: any
    icon: any
    path?: any
    notifications?: any
    handler?: any
}

export default defineComponent({
    name: 'DropdownUser',
    props: {
        options: {
            type: Array as () => Array<Option>,
            required: true,
        },
    },
})
</script>

<template>
    <div class="header-user-dropdown">
        <ul class="header-user-dropdown__items">
            <li v-for="option in options" :key="option.title" class="header-user-dropdown__item">
                <NuxtLink no-prefetch v-if="option.path" :to="option.path" class="option" rel="follow">
                    <picture class="option__image">
                        <img
                            :src="$route.path === option.path ? option.icon.active : option.icon.inactive"
                            :alt="option.title"
                            width="6px"
                            height="6px"
                        />
                    </picture>
                    <p
                        class="option__text"
                        :class="
                            $route.path === option.path ? 'font-semibold text-site-primary' : 'text-gray-800'
                        "
                    >
                        {{ option.title }}
                    </p>
                    <p v-if="option.notifications" class="option__notifications">
                        {{ option.notifications }}
                    </p>
                </NuxtLink>
                <button v-else class="option" type="button" @click="option.handler">
                    <picture class="option__image">
                        <img :src="option.icon" :alt="option.title" width="6px" height="6px" />
                    </picture>
                    <p class="option__text text-gray-800">{{ option.title }}</p>
                </button>
            </li>
        </ul>
    </div>
</template>

<style lang="postcss" scoped>
.header-user-dropdown {
    &__items {
        @apply relative h-screen rounded-b-lg border-l border-gray-200 bg-white py-2 shadow shadow-gray-200 md:h-auto;
    }

    &__item {
        @apply px-2 py-1.5 md:py-0;
        &:nth-child(8) {
            @apply mt-1 border-t pt-3 md:pt-1;
        }
        .option {
            @apply flex h-7 w-full items-center justify-start space-x-2 px-3 py-1 transition-colors lg:rounded-lg lg:hover:bg-gray-100;

            &__text {
                @apply flex-none text-base md:text-sm;
            }
            &__image {
                @apply h-5 w-5 flex-none md:h-4 md:w-4;

                img {
                    @apply h-full w-full rounded-none object-contain;
                }
            }
            &__notifications {
                @apply block rounded-full bg-site-primary p-0.5 px-1.5 text-center text-xs text-white shadow-xl md:hidden;
            }
        }
    }
}
</style>
