<template>
    <footer
        v-if="footer"
        id="foot"
        class="site-layout-footer"
        :class="$config.public.variant === 'bchollos' ? 'bg-site-secondary text-white' : 'bg-white'"
    >
        <div class="site-layout-footer-sections">
            <div class="social">
                <div v-if="$config.public.variant === 'megadescuentos'" class="social__telegram">
                    <h3>{{ $lang.components.layoutFooter.follow_in_telegram }}</h3>
                    <a
                        :href="$lang.pages.dashboardAjustes.telegram_url"
                        target="_blank"
                        rel="nofollow"
                        class="social__telegram-button"
                    >
                        <i class="fab fa-telegram-plane fa-lg text-white"></i>
                        {{ $lang.pages.dashboardAjustes.go_telegram }}
                    </a>
                </div>
                <div>
                    <FooterRRSS :rrss="footer.rrss" />
                </div>
            </div>

            <div class="image">
                <NuxtLink no-prefetch to="/" aria-label="Go home" rel="follow">
                    <img :src="$assets.brand.footerLogo" :alt="$lang.brand" :title="$lang.brand" />
                </NuxtLink>
                <p class="image__text">
                    <span class="hidden xl:block">{{ $lang.components.layoutFooter.community }}</span>
                    <span class="font-semibold">{{ $lang.components.layoutFooter.first_of }}</span>
                    <span class="hidden xl:block">
                        {{ $lang.components.layoutFooter.enjoy }}
                    </span>
                </p>
            </div>

            <div class="column">
                <h3 class="column__head">
                    <picture class="column__icon">
                        <img
                            :src="$assets.gray.link"
                            width="16px"
                            height="16px"
                            :alt="footer.firsts_title"
                            :title="footer.firsts_title"
                        />
                    </picture>
                    <span class="column__text">{{ footer.firsts_title }}</span>
                </h3>
                <ul class="column__list">
                    <li v-for="(link, index) in footer.firsts" :key="index">
                        <a
                            v-if="link.external"
                            :href="link.url"
                            class="column__link"
                            :class="
                                $config.public.variant === 'bchollos'
                                    ? 'text-white'
                                    : 'text-gray-800 hover:text-site-primary'
                            "
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            {{ link.title }}
                        </a>
                        <NuxtLink
                            no-prefetch
                            v-else
                            :to="link.url"
                            class="column__link"
                            :class="
                                $config.public.variant === 'bchollos'
                                    ? 'text-white'
                                    : 'text-gray-800 hover:text-site-primary'
                            "
                            >{{ link.title }}</NuxtLink
                        >
                    </li>
                </ul>
            </div>
            <div class="column">
                <h3 class="column__head">
                    <picture class="column__icon">
                        <img
                            :src="$assets.gray.user"
                            width="16px"
                            height="16px"
                            :alt="footer.seconds_title"
                            :title="footer.seconds_title"
                        />
                    </picture>
                    <span class="column__text">{{ footer.seconds_title }}</span>
                </h3>
                <ul class="column__list">
                    <li v-for="(link, index) in footer.seconds" :key="index">
                        <a
                            v-if="link.external"
                            :href="link.url"
                            class="column__link"
                            :class="
                                $config.public.variant === 'bchollos'
                                    ? 'text-white'
                                    : 'text-gray-800 hover:text-site-primary'
                            "
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            {{ link.title }}
                        </a>
                        <NuxtLink
                            no-prefetch
                            v-else
                            :to="link.url"
                            class="column__link"
                            :class="
                                $config.public.variant === 'bchollos'
                                    ? 'text-white'
                                    : 'text-gray-800 hover:text-site-primary'
                            "
                            >{{ link.title }}</NuxtLink
                        >
                    </li>
                </ul>
            </div>
            <div class="column">
                <h3 class="column__head">
                    <picture class="column__icon">
                        <img
                            :src="$assets.gray.footer"
                            width="16px"
                            height="16px"
                            :alt="footer.fourths_title"
                            :title="footer.fourths_title"
                        />
                    </picture>
                    <span class="column__text">{{ footer.thirds_title }}</span>
                </h3>
                <ul class="column__list">
                    <li v-for="(link, index) in footer.thirds" :key="index">
                        <a
                            v-if="link.external"
                            :href="link.url"
                            class="column__link"
                            :class="
                                $config.public.variant === 'bchollos'
                                    ? 'text-white'
                                    : 'text-gray-800 hover:text-site-primary'
                            "
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            {{ link.title }}
                        </a>
                        <NuxtLink
                            no-prefetch
                            v-else
                            :to="link.url"
                            class="column__link"
                            :class="
                                $config.public.variant === 'bchollos'
                                    ? 'text-white'
                                    : 'text-gray-800 hover:text-site-primary'
                            "
                            >{{ link.title }}</NuxtLink
                        >
                    </li>
                </ul>
            </div>
            <div class="column">
                <h3 class="column__head">
                    <picture class="column__icon">
                        <img
                            :src="$assets.gray.footer"
                            width="16px"
                            height="16px"
                            :alt="footer.fourths_title"
                            :title="footer.fourths_title"
                        />
                    </picture>
                    <span class="column__text">{{ footer.fourths_title }}</span>
                </h3>
                <ul class="column__list">
                    <li v-for="(link, index) in footer.fourths" :key="index">
                        <a
                            v-if="link.external"
                            :href="link.url"
                            class="column__link"
                            :class="
                                $config.public.variant === 'bchollos'
                                    ? 'text-white'
                                    : 'text-gray-800 hover:text-site-primary'
                            "
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            {{ link.title }}
                        </a>
                        <NuxtLink
                            no-prefetch
                            v-else
                            :to="link.url"
                            class="column__link"
                            :class="
                                $config.public.variant === 'bchollos'
                                    ? 'text-white'
                                    : 'text-gray-800 hover:text-site-primary'
                            "
                            >{{ link.title }}</NuxtLink
                        >
                    </li>
                </ul>
            </div>

            <div class="legal">
                <ul class="legal__links">
                    <li v-for="(link, index) in legalLinks" :key="index">
                        <NuxtLink no-prefetch :to="link.url">{{ link.title }}</NuxtLink>
                    </li>
                </ul>
                <FooterLocation :countries="dropdownCountries" />
            </div>
        </div>
    </footer>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'
const RootStore = useRootStore()
const { $lang } = useNuxtApp()
const {
    public: { variant },
} = useRuntimeConfig()

const locationOptions = [
    {
        variant: 'bchollos' as 'bchollos',
        url: 'https://www.bchollos.es',
    },
    {
        variant: 'megadescuentos' as 'megadescuentos',
        url: 'https://www.megadescuentos.com',
    },
    {
        variant: 'jdescuentos' as 'jdescuentos',
        url: 'https://www.jdescuentos.cl/',
    },
]

const footer = RootStore.layoutData?.data.footer
const dropdownCountries = locationOptions.filter((location) => location.variant !== variant)

const legalLinks = [
    {
        title: $lang.components.layoutFooter.privacy_policy,
        url: '/' + $lang.routes.privacy,
    },
    {
        title: $lang.components.layoutFooter.terms_of_use,
        url: '/' + $lang.routes.terms,
    },
    {
        title: $lang.components.layoutFooter.cookies_policy,
        url: '/' + $lang.routes.cookies,
    },
]
</script>

<style lang="postcss" scoped>
.site-layout-footer {
    @apply border-t;
    &-sections {
        @apply container grid grid-cols-2 gap-5 px-2 pb-4 pt-8 md:grid-cols-4 lg:col-span-3 lg:grid-cols-12 lg:gap-x-8;
        .image {
            @apply col-span-2 flex flex-col justify-start md:col-span-4 lg:col-span-12 xl:col-span-2;
            a {
                @apply mb-4 block w-32 sm:w-44;
            }
            img {
                @apply h-full w-full object-contain;
            }
            &__text {
                @apply text-sm;
            }
        }
        .social {
            @apply col-span-2 mb-4 space-y-4 border-b pb-6 md:col-span-4 md:flex md:justify-between md:space-x-5 md:space-y-0 lg:col-span-12;
            &__newsletter,
            &__telegram {
                @apply max-w-md flex-grow;
                h3 {
                    @apply mb-4 text-base font-semibold;
                }
                &-button {
                    @apply mt-3 flex items-center justify-center gap-2 rounded-lg bg-[#29A9EB] p-4 px-10 text-white lg:w-fit;
                }
            }

            &__form-item {
                @apply flex;
                .input {
                    @apply flex-grow;
                    input {
                        @apply block h-12 rounded-r-none;
                    }
                }
                .button {
                    @apply flex h-12 flex-none items-center rounded-r-xl bg-site-primary px-4 text-sm font-medium text-white;
                }
                /* .button {
                    @apply text-center justify-center flex-none w-56 h-12 px-4 flex items-center text-sm font-medium bg-site-primary text-white rounded-xl;
                } */
            }
        }
        .legal {
            @apply col-span-2 mt-4 flex flex-col items-center space-y-2 border-t pt-4 md:col-span-4 lg:col-span-12 lg:flex-row lg:justify-end lg:space-x-4 lg:space-y-0;

            &__links {
                @apply flex h-[14px] space-x-2;
                li {
                    @apply block;
                }
                a {
                    @apply block text-xs font-semibold leading-[14px] underline;
                }
            }
        }
        .column {
            @apply lg:col-span-3 xl:col-span-2;
            &__head {
                @apply mb-3 flex gap-1;
            }
            &__icon {
                @apply h-4 w-4 self-center;
                img {
                    @apply h-full w-full object-contain;
                }
            }
            &__text {
                @apply font-semibold;
            }
            &__list {
                @apply space-y-2;
            }
            &__link {
                @apply transition-colors duration-100;
            }
        }
    }
}
</style>
