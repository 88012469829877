<template>
    <div class="layout-overlay" :class="mode"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'LayoutOverlay',
    props: {
        mode: {
            type: String,
            default: 'normal',
        },
    },
})
</script>

<style lang="postcss" scoped>
.layout-overlay {
    @apply left-0 top-0 h-full w-full;
    &.normal {
        @apply bg-[#180025] opacity-20;
    }
    &.dark {
        @apply bg-[#180025] opacity-50;
    }
    &.light {
        @apply bg-gray-500 opacity-30;
    }
}
</style>
