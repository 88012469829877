<template>
    <div v-if="countries" class="footer-location-dropdown">
        <button ref="$button" class="footer-location-dropdown__button" @click="() => toggleDropdown()">
            <img width="12px" height="12px" :src="currentFlag" alt="flag" class="flag" />
            <p>{{ currentSite }}</p>
            <img
                :src="$assets.gray.arrowDown"
                alt="icon"
                width="12px"
                height="12px"
                :class="{ top: showOptions }"
            />
        </button>

        <ul v-if="showOptions" ref="$list" class="footer-location-dropdown__options open-up">
            <li v-for="(option, index) in countries" :key="index">
                <a :href="option.url" rel="nofollow" target="_self">
                    <img :src="countriesFlags[option.variant]" alt="flag" />{{
                        countriesCodes[option.variant]
                    }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
const { public: config } = useRuntimeConfig()

const props = defineProps({
    countries: Array as PropType<{ url: string; variant: 'megadescuentos' | 'bchollos' | 'jdescuentos' }[]>,
})

const showOptions = ref(false)

const $list = ref()
const $button = ref()

const countriesCodes = {
    bchollos: 'España (ES)',
    megadescuentos: 'México (MX)',
    jdescuentos: 'Chile (CL)',
} as { [x: string]: string }

const countriesFlags = {
    bchollos: '/assets/png/es.png',
    megadescuentos: '/assets/png/mx.png',
    jdescuentos: '/assets/png/cl.png',
} as { [x: string]: string }

const currentSite = computed(() => {
    return countriesCodes[config.variant] || 'México (MX)'
})
const currentFlag = computed(() => {
    return countriesFlags[config.variant] || countriesFlags.megadescuentos
})

const toggleDropdown = (show?: boolean) => {
    const newValue = show === undefined ? !showOptions.value : show

    showOptions.value = newValue
}
onClickOutside($list, () => toggleDropdown(false))
</script>

<style lang="postcss" scoped>
.footer-location-dropdown {
    @apply relative text-sm text-gray-800;
    &__button {
        @apply flex w-36 items-center justify-between space-x-2 rounded border border-gray-200 bg-gray-100 px-1 py-0.5 text-xs lg:hover:bg-gray-200;
        min-width: 100px;
        img {
            @apply h-2 w-2 transition-transform;

            &.top {
                @apply -rotate-180 transform;
            }
            &.flag {
                @apply h-4 w-4;
            }
        }
        p {
            @apply flex-grow text-left;
        }
    }
    &__options {
        @apply absolute left-0 z-50 w-full min-w-max rounded border border-gray-200 bg-gray-100 text-left;
        li {
            a {
                @apply flex whitespace-nowrap px-1 py-0.5 text-xs lg:hover:bg-gray-200;
                img {
                    @apply mr-2 block h-4 w-4;
                }
            }
        }
        &.open-up {
            @apply bottom-full;
        }
        &.open-down {
            @apply top-full;
        }
    }
}
</style>
